import React from "react";

const Logout = ({ fill, opacity }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.045"
      height="29.1"
      viewBox="0 0 29.045 29.1"
      style={{
        // marginLeft: 100,
        marginBottom: 7,
      }}
    >
      <g
        id="그룹_8440"
        data-name="그룹 8440"
        transform="translate(-45.478 -978)"
        opacity={opacity}
      >
        <path
          id="패스_27120"
          data-name="패스 27120"
          d="M45.5,992.534c0-3.229.058-6.46-.017-9.688A4.684,4.684,0,0,1,50.329,978c3.659.066,7.319.011,10.979.018a4.4,4.4,0,0,1,4.555,3.663,16.841,16.841,0,0,1,.07,3.438,1.018,1.018,0,0,1-.985.833A1.042,1.042,0,0,1,63.8,985.2a5.99,5.99,0,0,1-.1-1.223c-.016-.446,0-.893-.005-1.34a2.24,2.24,0,0,0-2.37-2.346c-1.907-.014-3.815,0-5.722,0-1.769,0-3.54.031-5.309-.01a2.393,2.393,0,0,0-2.531,2.53q.052,9.739,0,19.48a2.418,2.418,0,0,0,2.571,2.545q5.412-.057,10.824,0a2.369,2.369,0,0,0,2.547-2.557,18.013,18.013,0,0,1,0-1.958,1.14,1.14,0,0,1,.874-1.14,1.1,1.1,0,0,1,1.214.474.655.655,0,0,1,.148.322,12.831,12.831,0,0,1-.2,4.036,4.442,4.442,0,0,1-4.157,3.068c-3.9.026-7.8.035-11.7,0a4.424,4.424,0,0,1-4.361-4.338c-.046-3.4-.012-6.8-.012-10.2Z"
          fill={fill}
        />
        <path
          id="패스_27121"
          data-name="패스 27121"
          d="M71.205,993.761H58.331c-.155,0-.31.005-.464,0a1.156,1.156,0,0,1-1.24-1.161,1.123,1.123,0,0,1,1.269-1.113q6.362-.009,12.724,0h.763c-.572-.578-1.043-1.056-1.516-1.532-.706-.712-.806-1.34-.3-1.87.539-.562,1.173-.473,1.911.265.715.716,1.411,1.454,2.152,2.144a2.974,2.974,0,0,1,.008,4.234c-.8.749-1.555,1.552-2.339,2.322a1.134,1.134,0,0,1-1.725.107,1.146,1.146,0,0,1,.136-1.724c.521-.514,1.058-1.011,1.589-1.516Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Logout;
