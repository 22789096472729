import React from "react";
import { ReactComponent as X } from "../static/X.svg";
import { ReactComponent as Complete } from "../static/complete.svg";
import { ReactComponent as CheckIcon } from "../static/checkicon.svg";
import { useState } from "react";
import { Select } from "semantic-ui-react";
import axios from "axios";

const IssueRegister = ({
  setWriteIssue,
  selectEquipmentId,
  token,
  handleBreakListCall,
  fetchIssueCallback,
  selectIssueId,
  getNotesWithIssueId,
  // fetchDataCallback,
}) => {
  const options = [
    { key: "선택", text: "수리상태 선택" },
    { key: "OPENED", value: "접수", text: "접수" },
    { key: "WAITING_APPROVAL", value: "승인요청", text: "승인요청" },
    { key: "DISCARDED", value: "수리포기", text: "수리포기" },
    { key: "CLOSED", value: "수리완료", text: "수리완료" },
    { key: "IN_PROGRESS", value: "진행중", text: "진행중" },
    { key: "UNKNOWN", value: "알수없음", text: "알수없음" },
  ];

  const [belong, setBelong] = useState(""); //이벤트에서 소속
  const [name, setName] = useState(""); //이벤트에서 이름
  const [rank, setRank] = useState(""); //이벤트에서 직급
  const [contact, setContact] = useState(""); //이벤트에서 연락처
  const [selectStatusText, setSelectStatusText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [firstPhoneNum, setFirstPhoneNum] = useState("");
  const [secondPhoneNum, setSecondPhoneNum] = useState("");
  const [thirdPhoneNum, setThirdPhoneNum] = useState("");

  const isNumeric = (value) => /^[0-9]*$/.test(value);

  const handleFirstPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 3);
    if(isNumeric(inputText)){
      setFirstPhoneNum(inputText);
      updateContact(`${inputText}-${secondPhoneNum}-${thirdPhoneNum}`);
    }
  };

  const handleSecondPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 4);
    if(isNumeric(inputText)){
      setSecondPhoneNum(inputText);
      updateContact(`${firstPhoneNum}-${inputText}-${thirdPhoneNum}`);
    }
  };

  const handleThirdPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 4);
    if(isNumeric(inputText)){
      setThirdPhoneNum(inputText);
      updateContact(`${firstPhoneNum}-${secondPhoneNum}-${inputText}`);
    }
  };

  const updateContact = (newContact) => {
    setContact(newContact);
  };

  

  async function addIssue(equipmentId) {
    const api = `https://api.the-kb.com/v1/issue`;

    const countOfIssue = await axios.get(api + "/list", {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (
      selectStatusText === "" ||
      noteContent === "" ||
      belong === "" ||
      name === "" ||
      rank === "" ||
      contact === ""
    ) {
      alert("비어있는 항목값이 있습니다.");
      return null;
    }

    try {
      const postRes = await axios.post(
        api,
        {
          equipmentId: equipmentId,
          note: {
            status: selectStatusText,
            text: noteContent,
            writer: {
              group: belong,
              name: name,
              title: rank,
              contact: contact,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (postRes.status !== 404) {
        const tempList = await axios.get(api + "/list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        handleBreakListCall(selectEquipmentId);
      }

      alert("수리 내역 등록이 완료되었습니다.");
      // fetchDataCallback();
      setSelectStatusText("");
      setSelectedStatus("");
      setNoteContent("");
      setBelong("");
      setName("");
      setRank("");
      setContact("");
      setWriteIssue(false);
      fetchIssueCallback();
      // getNotesWithIssueId(selectIssueId);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        width: "1920px",
        height: "1080px",
        backgroundColor: "rgba(0, 0, 0, .75)",
      }}
    >
      <div className="popup popup-3">
        <X
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
          }}
          onClick={(e) => {
            setWriteIssue(false);
          }}
        />
        <Complete />
        <div
          style={{
            marginTop: "30px",
            color: "#FFF",
            fontSize: "35px",
            fontFamily: "appler",
            fontWeight: 400,
          }}
        >
           수리 내역을 작성해주세요.
        </div>
        <div
          style={{
            marginTop: "60px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Select
            placeholder="수리상태 선택"
            options={options}
            style={{
              fontSize: "20px",
              marginTop: "13px",
              marginBottom: "13px",
              height: "53px",
              width: "480px",
              backgroundColor: "#626468",
            }}
            value={selectedStatus}
            onChange={(e) => {
              const selectedOption = options.find(
                (option) => option.text === e.currentTarget.textContent
              );
              setSelectedStatus(selectedOption.text)
              setSelectStatusText(selectedOption.key);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "60px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            소속*
          </span>
          <div
            contentEditable
            id="belong"
            style={{
              width: "414px",
              color: "#FFF",
              fontSize: "20px",
              fontFamily: "appler",
              fontWeight: 400,
              paddingBottom: "16px",
              borderBottom: "1px solid #4e5054",
              outline: 0,
            }}
            placeholder="소속을 입력해주세요."
            onInput={(e) => {
              setBelong(e.currentTarget.textContent);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            이름*
          </span>
          <div
            contentEditable
            id="name"
            style={{
              width: "414px",
              color: "#FFF",
              fontSize: "20px",
              fontFamily: "appler",
              fontWeight: 400,
              paddingBottom: "16px",
              borderBottom: "1px solid #4e5054",
              outline: 0,
            }}
            placeholder="이름을 입력해주세요."
            onInput={(e) => {
              setName(e.currentTarget.textContent);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            직급*
          </span>
          <div
            contentEditable
            id="rank"
            style={{
              width: "414px",
              color: "#FFF",
              fontSize: "20px",
              fontFamily: "appler",
              fontWeight: 400,
              paddingBottom: "16px",
              borderBottom: "1px solid #4e5054",
              outline: 0,
            }}
            placeholder="직급을 입력해주세요."
            onInput={(e) => {
              setRank(e.currentTarget.textContent);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "row",
            marginLeft: "-19%",
          }}
        >
          <span
            style={{
              marginLeft: "80px",
              color: "#FFF",
              fontSize: "21px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            연락처*
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              placeholder="000"
              value={firstPhoneNum}
              maxLength={3}
              onChange={handleFirstPartChange}
              style={{
                textAlign: "center",
                color: "white",
                fontSize: "20px",
                height: "55px",
                maxWidth: "80px",
                backgroundColor: "transparent",
                border: "1px solid #75777b",
                marginLeft: "20px",
                marginRight: "4px",
              }}
            />
            <div
              style={{
                marginLeft: "12px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "appler",
                fontWeight: "400",
              }}
            >
              -
            </div>
            {/* 두 번째 부분 */}
            <input
              type="text"
              placeholder="0000"
              value={secondPhoneNum}
              maxLength={4}
              onChange={handleSecondPartChange}
              style={{
                textAlign: "center",
                height: "55px",
                fontSize: "20px",
                maxWidth: "100px",
                color: "white",
                backgroundColor: "transparent",
                border: "1px solid #75777b",
                marginLeft: "20px",
                marginRight: "4px",
              }}
            />
            <div
              style={{
                marginLeft: "12px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "appler",
                fontWeight: "400",
              }}
            >
              -
            </div>
            {/* 세 번째 부분 */}
            <input
              type="text"
              placeholder="0000"
              value={thirdPhoneNum}
              maxLength={4}
              onChange={handleThirdPartChange}
              style={{
                textAlign: "center",
                height: "55px",
                fontSize: "20px",
                maxWidth: "100px",
                color: "white",
                backgroundColor: "transparent",
                border: "1px solid #75777b",
                marginLeft: "20px",
                marginRight: "4px",
              }}
            />
          </span>
        </div>
        <div
          contentEditable
          className="update-write-content"
          onInput={(e) => {
            setNoteContent(e.currentTarget.textContent);
          }}
        />
        {/* 작업내용 */}
        <div
          style={{
            fontSize: "20px",
            marginTop: "30px",
            marginLeft: "41px",
            color: "#FC4E47",
            alignSelf: "flex-start",
            marginBottom: "10px",
          }}
        >
          *입력사항을 전부 입력해주세요.
        </div>
        <button
          className="update-btn"
          style={{
            margin: "10px 10px",
          }}
          onClick={() => {
            addIssue(selectEquipmentId);
          }}
        >
          <CheckIcon
            style={{
              marginRight: "5px",
            }}
            width={32}
            height={25}
          />
          작성완료
        </button>
      </div>
    </div>
  );
};

export default IssueRegister;
