import React from "react";

const Search = ({ fill, opacity }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.992"
      height="34.979"
      viewBox="0 0 34.992 34.979"
      style={{
        marginBottom: 7,
      }}
    >
      <g
        id="그룹_8439"
        data-name="그룹 8439"
        transform="translate(-42.504 -303.52)"
        opacity={opacity}
        style={{
          marginBottom: 7,
        }}
      >
        <path
          id="패스_27117"
          data-name="패스 27117"
          d="M63.881,328.542a13.733,13.733,0,1,1,3.59-3.59c1.588.619,2.512,2.018,3.671,3.136,1.874,1.809,3.709,3.66,5.524,5.53a2.8,2.8,0,0,1,.18,3.785,2.835,2.835,0,0,1-3.678.7,4.088,4.088,0,0,1-.817-.658c-2.492-2.48-4.985-4.959-7.452-7.463A10.92,10.92,0,0,1,63.881,328.542ZM56.19,306.976A10.211,10.211,0,1,0,56.2,327.4a10.211,10.211,0,0,0-.007-20.422Z"
          fill={fill}
        />
        <path
          id="패스_27118"
          data-name="패스 27118"
          d="M56.142,324.228q-2.772,0-5.546,0c-.758,0-.993-.341-.934-1.107a6.043,6.043,0,0,1,5.141-5.235,6.662,6.662,0,0,1,6.825,2.57,5.129,5.129,0,0,1,1.005,2.9c.031.56-.267.865-.852.866C59.9,324.231,58.022,324.228,56.142,324.228Z"
          fill={fill}
        />
        <path
          id="패스_27119"
          data-name="패스 27119"
          d="M56.125,317.028a3.6,3.6,0,1,1,3.623-3.588A3.616,3.616,0,0,1,56.125,317.028Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Search;
