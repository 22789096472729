import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Sidebar as SideBar,
  Segment,
  Table,
  Grid,
  Button,
  Pagination,
  Input,
  Dropdown,
} from "semantic-ui-react";
import Loading from "./Loading";
import { ReactComponent as RightArrow } from "../static/right_arrow.svg";
import { ReactComponent as RightArrowBtn } from "../static/right_arrow2.svg";
import { ReactComponent as LeftArrowBtn } from "../static/left_arrow.svg";
import { ReactComponent as PlusBtn } from "../static/plusbtn.svg";
import { ReactComponent as Complitive } from "../static/complitive.svg";
import { ReactComponent as Progressive } from "../static/progressive.svg";
import Empty from "./empty";
import { useAuth } from "../AuthContext";

const As = ({
  // fetchDataCallback,
  fetchIssueCallback,
  openImagePreview,
  clickedDiv,
  setClickDiv,
  handleBreakListCall,
  setSelectNoteId,
  selectNoteId,
  selectedIndex,
  imageUrls,
  loading,
  handlePreview,
  tempNoteAttachmentList
}) => {
  const { asAllData: issueDataAs, allData, token } = useAuth();
  const [searchAsData, setSearchAsData] = useState("");
  const [filterAsData, setFilterAsData] = useState([]); //검색후 결과 데이터들
  const [currentPage, setCurrentPage] = useState(1); //현재 페이지
  const [eventAddress, setEventAddress] = useState(""); // 추가된 주소 필터 상태 변수
  const [breakList, setBreakList] = useState(false);
  const [index, setIndex] = useState(0); //이슈 목록 번호
  const [selectedAsName, setSelectedAsName] = useState("");
  const [defaultNote, setDefaultNote] = useState([]);
  const [selectedAsId, setSelectedAsId] = useState("");
  const [selectIssueId, setSelectIssueId] = useState("");
  const [selectEquipmentId, setSelectEquipmentId] = useState("");
  const [asDetail, setAsDetail] = useState(false);
  const [breakIssue, setBreakIssue] = useState([]);
  const [noteData, setNoteData] = useState([]);
  const [checkBroken, setCheckBroken] = useState(false);
  const [eventEnergyFilter, setEventEnergyFilter] = useState("");
  const [asCurrentData, setAsCurrentData] = useState([]);
  const [tempNoteList, setTempNoteList] = useState([]);
  const [asSearchOn, setAsSearchOn] = useState([]);

  const options = [
    { key: "선택", text: "수리상태 선택" },
    { key: "OPENED", value: "접수", text: "접수" },
    { key: "WAITING_APPROVAL", value: "승인요청", text: "승인요청" },
    { key: "DISCARDED", value: "수리포기", text: "수리포기" },
    { key: "CLOSED", value: "수리완료", text: "수리완료" },
    { key: "IN_PROGRESS", value: "진행중", text: "진행중" },
    { key: "UNKNOWN", value: "알수없음", text: "알수없음" },
  ];

  const [asDataPerPage, setAsDataPerPage] = useState(10);

  const noteDataCallback = useCallback(
    async (index) => {
      const api = `https://api.the-kb.com/v1/issue/note/${index}`;
      const defaultApi = `https://api.the-kb.com/v1/issue/list`;

      try {
        let res;
        if (index) {
          res = await axios.get(api, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } else {
          res = await axios.get(defaultApi, {
            headers: { Authorization: `Bearer ${token}` },
          }); // 기본 데이터를 가져오는 API 호출
        }

        if (res.data) {
          setNoteData(res.data);
          // console.log(res.data)
        } else {
          const defaultData = defaultNote;
          setNoteData(defaultData[0]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [defaultNote]
  );

  const getNotesWithIssueId = async (issueId) => {
    const response = await axios.get(
      `https://api.the-kb.com/v1/issue/${issueId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    response.data.notes.length > 0 && setTempNoteList(response.data.notes);

    try {
      if (selectNoteId) {
        const responseAfter = await axios.get(
          `https://api.the-kb.com/v1/issue/note/${selectNoteId}` //빈 노트시 여기서 get에러뜸
        ,{
          headers: { Authorization: `Bearer ${token}` },
        });
        setNoteData(responseAfter.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getIssue(equipmentId) {
    const api = `https://api.the-kb.com/v1/issue/list?equipmentId=${equipmentId}`;

    try {
      const res = await axios.get(api, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBreakIssue(res.data);
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  const handleAsRefresh = () => {
    setCurrentSlide(0);
    setCurrentPage(1);
    setFilterAsData([]);
    setEventEnergyFilter("");
    setEventAddress("");
    setSearchAsData("");
    // fetchDataCallback();
    fetchIssueCallback();
    setAsDataPerPage(10);
    setAsDetail(false);
    setBreakList(false);
  };

  //주소 필터
  const handleEventAddress = (e, { value }) => {
    setCurrentPage(1);
    setEventAddress(value === "주소" ? "" : value);
  };

  // 15분마다 새로고침 되게
  useEffect(() => {
    function fetchBothCallbacks() {
      fetchIssueCallback();
      // fetchDataCallback();
    }

    fetchBothCallbacks();

    const interval = setInterval(fetchBothCallbacks, 900000);

    return () => clearInterval(interval);
  }, []);

  // A/S 기본
  const asPageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  useEffect(() => {
    tempNoteAttachmentList.forEach((attachment) => {
      if (!imageUrls[attachment.id]) {
        handlePreview(attachment.id);
      }
    });
  }, [tempNoteAttachmentList,token,handlePreview,imageUrls]);

  //오리지날
  const handleSearchAs = (id) => {
    const matchingDataName = allData.filter((item) => item.id === id);
    const matchingData = issueDataAs.filter((item) => item.equipmentId === id);
    const notes = matchingData[0].notes;
    const lastData = notes[notes.length - 1];
    setDefaultNote(lastData);

    if (matchingDataName) {
      setSelectedAsName(matchingDataName[0].owner);
    }

    setSelectedAsId(id);

    if (id === selectedAsId) {
      let perPage;
      if (asDetail || breakList) {
        setCurrentPage(1);
        setAsDetail(false);
        setBreakList(false);
        perPage = 10;
      } else if (!breakList || !asDetail) {
        setBreakList(true);
        perPage = 3;
      } else {
        setCurrentPage(1);
        setBreakList(false);
        perPage = 10;
      }
      setAsDataPerPage(perPage);
    } else {
      setBreakList(true);
      setAsDetail(false);
      setAsDataPerPage(3);
    }
  };

  const asStartIndex = (currentPage - 1) * asDataPerPage;
  const asEndIndex = asStartIndex + asDataPerPage;

  //이슈 인덱스 설정
  const issuesPerSlide = 3; // 한 번에 표시할 이슈 수

  const [currentSlide, setCurrentSlide] = useState(0);

  const startIssueIndex = currentSlide * issuesPerSlide;
  const endIssueIndex = startIssueIndex + issuesPerSlide;

  const isFirstSlide = currentSlide === 0; // 슬라이드 첫페이지
  const isLastSlide = endIssueIndex >= breakIssue.length; // 슬라이드 마지막

  //에너지 필터
  const handleEventEnergyFilter = (e, { value }) => {
    setCurrentPage(1);
    setEventEnergyFilter(value === "상태" ? "" : value);
  };

  const [totalAsPages, setTotalAsPages] = useState("");

  useEffect(() => {
    const filterData = allData.filter((item) =>
      issueDataAs.some((data) => data.equipmentId === item.id)
    );

    const normalData = filterData.filter(item => item.status === "정상"  )

    let updatedEventData = normalData;

    if (eventAddress) {
      updatedEventData = updatedEventData.filter((item) =>
        item.addressData.includes(eventAddress)
      );
    }

    if (eventEnergyFilter && eventEnergyFilter !== "에너지원") {
      updatedEventData = updatedEventData.filter(
        (item) => item.energy === eventEnergyFilter
      );
    }

    if (asSearchOn) {
      const searchTextLower = searchAsData.toLowerCase();
      updatedEventData = updatedEventData.filter((data) => {
        const addressMatch = data.addressData
          .toLowerCase()
          .includes(searchTextLower);
        const energyMatch =
          data.energy && data.energy.toLowerCase().includes(searchTextLower);
        const ownerMatch =
          data.owner && data.owner.toLowerCase().includes(searchTextLower);
        const installerMatch =
          data.installer &&
          data.installer.toLowerCase().includes(searchTextLower);
        const maintainerMatch =
          data.maintainer &&
          data.maintainer.toLowerCase().includes(searchTextLower);

        return (
          addressMatch ||
          energyMatch ||
          ownerMatch ||
          installerMatch ||
          maintainerMatch
        );
      });
    }

    const currentAsData = updatedEventData.slice(asStartIndex, asEndIndex);

    setAsCurrentData(currentAsData);

    setTotalAsPages(Math.ceil(updatedEventData.length / asDataPerPage));
  }, [
    eventAddress,
    eventEnergyFilter,
    searchAsData,
    asStartIndex,
    asEndIndex,
    issueDataAs,
    asSearchOn,
  ]);

  useEffect(() => {
    if (searchAsData === "") {
      setAsSearchOn(false);
    }
  }, [searchAsData]);

  return (
    <SideBar.Pushable>
      <Segment basic="basic" className="basic-segment">
        <Grid>
          <Grid.Row className="padding-zero" columns={9}>
            <Grid.Column className="as-history-title">
              A/S이력
              <button onClick={handleAsRefresh} className="eventButton">
                <i className="redo alternate icon"></i>
              </button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="padding-zero">
            <Grid.Column className="content-section">
              <Grid.Row>
                <Input
                  icon="search"
                  placeholder="검색어를 입력해주세요"
                  value={searchAsData}
                  onChange={(e) => {
                    setSearchAsData(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setAsSearchOn((prev) => !prev);
                    }
                  }}
                />
                <Button
                  className="btn-green"
                  onClick={() => setAsSearchOn((prev) => !prev)}
                >
                  조회하기
                </Button>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className="as-table-container"
            style={{
              minHeight : (breakList || asDetail) ? "300px" : "390px"
            }}
          >
            <Table celled inverted className="table-2">
              <Table.Header className="table-header-color">
                <Table.Row>
                  <Table.HeaderCell textAlign="center" style={{ width: "13%" }}>
                    <Dropdown
                      placeholder="에너지원"
                      selection="selection"
                      onChange={handleEventEnergyFilter}
                      value={eventEnergyFilter}
                      style={{
                        minWidth: 0,
                        zIndex: 999,
                        backgroundColor: "#2D2D31",
                        color: "rgba(255,255,255,.9)",
                        textAlign: "center",
                        overflow: "visible",
                      }}
                      options={[
                        {
                          text: "에너지원",
                          value: "에너지원",
                          style: {
                            border: "none",
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "태양광(단상)",
                          value: "태양광(단상)",
                          style: {
                            border: "none",
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "태양광(삼상)",
                          value: "태양광(삼상)",
                          style: {
                            border: "none",
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "태양열(자연식)",
                          value: "태양열(자연식)",
                          style: {
                            border: "none",
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "태양열(강제식)",
                          value: "태양열(강제식)",
                          style: {
                            border: "none",
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                      ]}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: "20%" }}>
                    사용자
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: "13%" }}>
                    설비 업체
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: "13%" }}>
                    모니터링 업체
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: "27%" }}>
                    <Dropdown
                      placeholder="주소"
                      selection="selection"
                      value={eventAddress}
                      options={[
                        {
                          text: "주소",
                          style: {
                            border: "none",
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "포항시",
                          value: "포항시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "구미시",
                          value: "구미시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "경산시",
                          value: "경산시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "경주시",
                          value: "경주시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "안동시",
                          value: "안동시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "김천시",
                          value: "김천시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "칠곡군",
                          value: "칠곡군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "영천시",
                          value: "영천시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "영주시",
                          value: "영주시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "상주시",
                          value: "상주시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "문경시",
                          value: "문경시",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "예천군",
                          value: "예천군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "의성군",
                          value: "의성군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "울진군",
                          value: "울진군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "성주군",
                          value: "성주군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "청도군",
                          value: "청도군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "영덕군",
                          value: "영덕군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "고령군",
                          value: "고령군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "봉화군",
                          value: "봉화군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "청송군",
                          value: "청송군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "군위군",
                          value: "군위군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "영양군",
                          value: "영양군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                        {
                          text: "울릉군",
                          value: "울릉군",
                          style: {
                            color: "white",
                            backgroundColor: "#323232",
                            borderColor: "#323232",
                            textAlign: "center",
                          },
                        },
                      ]}
                      onChange={handleEventAddress}
                      style={{
                        backgroundColor: "#2D2D31",
                        color: "rgba(255,255,255,.9)",
                        textAlign: "center",
                        zIndex: 999,
                      }}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: "9%" }}>
                    상태
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Loading />
                ) : asCurrentData.length > 0 ? (
                  asCurrentData.map((data) => {
                    // console.log(issueDataAs)
                    const filterData = issueDataAs?.find(
                      (item) => item.equipmentId === data.id
                    );

                    var address = data.addressData;
                    var energyData = data.energy;
                    var ownerTitle = data.owner;
                    var installerTitle = data.installer;
                    var maintainerTitle = data.maintainer;
                    var status =
                      filterData?.status.length > 0
                        ? filterData.status
                        : "CLOSED";

                    const asHandleClick = () => {
                      setClickDiv(null);
                      setCurrentSlide(0);
                      noteDataCallback(
                        filterData.notes[filterData.notes.length - 1].id
                      );
                      getIssue(filterData.equipmentId);
                      handleSearchAs(filterData.equipmentId);
                      setSelectIssueId(filterData.id);
                      setSelectEquipmentId(filterData.equipmentId);
                    };

                    return (
                      <Table.Row key={data.id}>
                        <Table.Cell
                          textAlign="center"
                          onClick={asHandleClick}
                          style={{ cursor: "pointer", height: "58px" }}
                        >
                          {energyData}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          onClick={asHandleClick}
                          style={{ cursor: "pointer", height: "58px" }}
                        >
                          {ownerTitle}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          onClick={asHandleClick}
                          style={{ cursor: "pointer", height: "58px" }}
                        >
                          {installerTitle}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          onClick={asHandleClick}
                          style={{ cursor: "pointer", height: "58px" }}
                        >
                          {maintainerTitle}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          onClick={asHandleClick}
                          style={{ cursor: "pointer", height: "58px" }}
                        >
                          {address}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          style={{ cursor: "default", height: "58px" }}
                        >
                          <button className="as-button" onClick={asHandleClick}>
                            <span>
                              {
                                options.find((item) => item.key === status)
                                  ?.text
                              }
                            </span>
                          </button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Empty />
                )}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
        <Grid.Row className="as-pagination">
          <Table.Footer>
            {asCurrentData.length > 0 ? (
              <Pagination
                activePage={currentPage}
                totalPages={totalAsPages}
                onPageChange={asPageChange}
              />
            ) : (
              ""
            )}
          </Table.Footer>
        </Grid.Row>
      </Segment>
      {breakList && (
        <Segment basic className="basic-segment2-break">
          <>
            <div className="break">
              고장 목록
              <span style={{ height: "50px" }}></span>
            </div>
            <div />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LeftArrowBtn
                style={{
                  marginLeft: "7px",
                  marginRight: "26px",
                  cursor: isFirstSlide ? "not-allowed" : "pointer",
                  opacity: isFirstSlide ? 0.2 : 1,
                }}
                onClick={() => {
                  if (!isFirstSlide) {
                    setCurrentSlide(currentSlide - 1);
                  }
                }}
              />
              {breakIssue
                .slice(startIssueIndex, endIssueIndex)
                .map((data, i) => {
                  // console.log(data);
                  return (
                    <Grid.Column
                      key={i}
                      className={`content-section-inside2 ${
                        i !== 0 && "margin"
                      }`}
                    >
                      <div
                        style={{
                          marginLeft: "18px",
                          color: "#FFF",
                          fontSize: "20px",
                          fontFamily: "nanumgothic",
                          fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {data.notes[0].postedAt
                          .split("T")[0]
                          .split("-")
                          .join(".")}
                        <RightArrow
                          style={{
                            cursor: "pointer",
                            marginLeft: "12px",
                          }}
                          onClick={() => {
                            getNotesWithIssueId(data.id);
                            setClickDiv(null);
                            setSelectIssueId(data.id);
                            setSelectEquipmentId(data.equipmentId);
                            setBreakList(false);
                            setAsDetail(true);
                          }}
                        />
                      </div>
                      <div />
                      <Grid
                        className="as progress wrapper"
                        as="div"
                        onClick={(e) => {
                          setSelectIssueId(data.id);
                          setSelectEquipmentId(data.equipmentId);
                          getNotesWithIssueId(data.id);
                          setBreakList(false);
                          setAsDetail(true);
                          setClickDiv(null);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {breakIssue.length > 0 &&
                          data.notes.map((item, index) => {
                            // console.log(item)
                            return (
                              <Grid.Row
                                key={index}
                                className="padding-zero"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: "3px",
                                }}
                              >
                                <Grid.Column
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  <Complitive
                                    style={{
                                      transform: "translateY(21px)",
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <div
                                    className={
                                      index === data.notes.length - 1
                                        ? "as progress contentbox isprogressing"
                                        : "as progress contentbox aligntop"
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        onClick={() => {
                                          setSelectNoteId(item.id);
                                        }}
                                      >
                                        {options.find(
                                          (data) => data.key === item.status
                                        )?.text || ""}
                                      </span>
                                      <span
                                        style={{ fontSize: "14px !important" }}
                                      >{`담당자: ${item.writer.name || ""} (${
                                        item.writer.contact || ""
                                      })`}</span>
                                    </div>
                                    {index === data.notes.length - 1
                                      ? item.postedAt &&
                                        item.postedAt
                                          .split("T")[0]
                                          .split("-")
                                          .join(".") + "~"
                                      : item.postedAt &&
                                        item.postedAt
                                          .split("T")[0]
                                          .split("-")
                                          .join(".")}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            );
                          })}
                        <Grid.Row
                          className="padding-zero"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid.Column
                            style={{
                              marginRight: 10,
                            }}
                          />
                          <Grid.Column>
                            {
                              <div className="as progress contentbox empty">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* 고장목록에서 항목보기 */}
                                  <span>
                                    <PlusBtn />
                                  </span>
                                  <span
                                    onClick={(e) => {
                                      setSelectIssueId(data.id);
                                      setSelectEquipmentId(data.equipmentId);
                                      getNotesWithIssueId(data.id);
                                      setBreakList(false);
                                      setAsDetail(true);
                                      setClickDiv(null);
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    항목별 세부정보 보기
                                  </span>
                                </div>
                              </div>
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  );
                })}
              <RightArrowBtn
                style={{
                  marginLeft: index === 0 ? "40px" : "26px",
                  marginRight: index === 0 ? "40px" : "-12px",
                  cursor: isLastSlide ? "not-allowed" : "pointer",
                  opacity: isLastSlide ? 0.2 : 1,
                }}
                onClick={() => {
                  if (!isLastSlide) {
                    setCurrentSlide(currentSlide + 1);
                  }
                }}
              />
            </div>
          </>
        </Segment>
      )}
      {asDetail &&
        breakIssue.map((data) => {
          const filterNote = breakIssue.filter(
            (item) => item.id === selectIssueId
          );

          return (
            <Segment basic className="as-segment2">
              <Grid>
                <Grid.Row className="padding-zero">
                  <Grid.Row
                    className={`content-section2 ${
                      checkBroken && "checkBroken"
                    }`}
                  >
                    {!checkBroken && (
                      <>
                        <Grid.Column
                          className="content-section-inside"
                          key={data.equipmentIdd}
                        >
                          <div
                            style={{
                              fontSize: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="as is processing">
                              {"A/S 진행중"}
                            </div>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleBreakListCall(selectEquipmentId);
                                setTempNoteList([]);
                                setClickDiv(null);
                                setSelectNoteId("");
                                setBreakList(true);
                                setAsDetail(false);
                              }}
                            >
                              <i
                                class="arrow left icon"
                                style={{ fontSize: "25px" }}
                              />
                            </span>
                          </div>
                          <div className="as title">{selectedAsName}</div>
                          <div className="as sub title1">
                            {clickedDiv !== null && noteData
                              ? noteData.writer.name
                              : ""}
                          </div>
                          <div className="as sub title2">
                            {clickedDiv !== null && noteData
                              ? noteData.writer.group
                              : ""}
                          </div>
                          <div className="as divider" />
                          <div className="as progress title">진행상황</div>
                          <Grid className="as progress wrapper" as="div">
                            {data.notes ? (
                              <>
                                {tempNoteList.map((note, index) => {
                                  // console.log(filterNote[0].notes)
                                  return (
                                    <Grid.Row
                                      className="padding-zero"
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "3px",
                                      }}
                                    >
                                      <Grid.Column style={{ marginRight: 10 }}>
                                        {index === clickedDiv ? (
                                          <Progressive
                                            style={{
                                              transform: "translateY(21px)",
                                            }}
                                          />
                                        ) : (
                                          <Complitive
                                            style={{
                                              transform: "translateY(21px)",
                                            }}
                                          />
                                        )}
                                      </Grid.Column>
                                      <Grid.Column>
                                        <div
                                          className={
                                            index === clickedDiv
                                              ? "as progress contentbox isprogressing"
                                              : "as progress contentbox aligntop"
                                          }
                                          style={{ justifyContent: "top" }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span
                                              onClick={() => {
                                                setClickDiv(index);
                                                noteDataCallback(note.id);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {options.find(
                                                (item) =>
                                                  item.key === note.status
                                              )?.text || ""}
                                            </span>
                                            <span
                                              style={{
                                                fontSize: "14px !important",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setClickDiv(index);
                                                noteDataCallback(note.id);
                                              }}
                                            >
                                              {`담당자: ${
                                                note.writer.name || ""
                                              } (${note.writer.contact || ""})`}
                                            </span>
                                          </div>
                                          <span>
                                            {index ===
                                            filterNote[0].notes.length - 1
                                              ? note.postedAt &&
                                                note.postedAt
                                                  .split("T")[0]
                                                  .split("-")
                                                  .join(".")
                                              : note.postedAt &&
                                                note.postedAt
                                                  .split("T")[0]
                                                  .split("-")
                                                  .join(".")}
                                          </span>
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}
                            <Grid.Row
                              className="padding-zero"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Grid.Column
                                style={{
                                  marginRight: 10,
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column className="content-section-inside withcustompadding">
                          <Grid.Row
                            className="padding-zero"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <Grid.Column className="padding-zero third">
                              <div>수리상태</div>
                              <div
                                style={{
                                  fontWeight: "700",
                                  color: "#00CC66",
                                  fontSize: "25px",
                                }}
                              >
                                {noteData
                                  ? options.find(
                                      (item) => item.key === noteData.status
                                    )?.text || ""
                                  : options.find(
                                      (item) => item.key === data.status
                                    )?.text || ""}
                              </div>

                              <div />
                              <div>작업자 정보</div>
                              <div className="aligned">
                                <span style={{ width: "63px" }}>소속</span>
                                <span>
                                  {noteData
                                    ? noteData.writer.group
                                    : defaultNote.writer.group}
                                </span>
                              </div>
                              <div className="aligned">
                                <span style={{ width: "63px" }}>이름</span>
                                <span>
                                  {noteData
                                    ? noteData.writer.name
                                    : defaultNote.writer.name}
                                </span>
                              </div>
                              <div className="aligned">
                                <span style={{ width: "63px" }}>직급</span>
                                <span>
                                  {noteData
                                    ? noteData.writer.title
                                    : defaultNote.writer.title}
                                </span>
                              </div>
                              <div className="aligned">
                                <span style={{ width: "74px" }}>연락처</span>
                                <span>
                                  {noteData
                                    ? noteData.writer.contact
                                    : defaultNote.writer.contact}
                                </span>
                              </div>
                            </Grid.Column>
                            <div
                              style={{
                                height: "427px",
                                marginRight: "1px solid #FFF",
                                opacity: 0.1,
                              }}
                            />
                            <Grid.Column className="padding-zero fourth">
                              <div>작업 내용</div>
                              <div>
                                <div className="readOnly-content">
                                  {noteData ? noteData.text : defaultNote.text}
                                </div>
                                <div
                                  className={
                                    noteData.attachments.length >= 3
                                      ? "as-fileContent-3"
                                      : noteData.attachments.length === 0
                                      ? "as-fileContent"
                                      : "as-fileContent-1"
                                  }
                                >
                                  <span>첨부파일</span>
                                  {/* <span>첨부파일</span> */}
                                  <div
                                    style={{
                                      maxWidth: "300px",
                                      maxHeight: "120px",
                                      overflowX: "auto",
                                      overflowY: "hidden",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      {noteData.attachments.length > 0 ? (
                                        noteData.attachments.map(
                                          (attachment) => (
                                            <div key={attachment.id}>
                                              <img
                                                style={{
                                                  width: "50px",
                                                  height: "60px",
                                                  margin: "0 5px 0 5px",
                                                  cursor: "pointer",
                                                }}
                                                src={imageUrls[attachment.id]}
                                                alt={attachment.filename}
                                                onClick={() =>
                                                  openImagePreview(
                                                    attachment.id
                                                  )
                                                }
                                              />
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <span>No Image</span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                className={
                                  noteData.attachments.length >= 3
                                    ? "check-button-3"
                                    : noteData.attachments.length === 0
                                    ? "check-button"
                                    : "check-button-1"
                                }
                                onClick={handleAsRefresh}
                              >
                                확인
                              </button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid.Column>
                      </>
                    )}
                  </Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>
          );
        })}
    </SideBar.Pushable>
  );
};

export default As;
