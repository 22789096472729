import Main from "./components/Main.js";
import { Container } from "semantic-ui-react";
import { createContext, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthContext.js";

function App() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 }); //모바일 
  const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1280 }); // 768px 이상 1320px 이하의 노트북
  const isDesktop = useMediaQuery({ minWidth: 1280 }); // 1320px 이상 전부

  const queryCleint = new QueryClient()

  return (
    <QueryClientProvider client={queryCleint}>
    <AuthProvider>
    <BrowserRouter>
      <Container
        style={{
          height: isMobile ? '100vh' : (isLaptop ? '100%' : (isDesktop ? '100%' : "1080px")),
          width: isMobile ? '75%' : (isLaptop ? '100%' : (isDesktop ? '100%' : "1920px")),
          backgroundColor: "#000000",
        }}
        >
        <Main
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          index={index}
          setIndex={setIndex}
          />
      </Container>
    </BrowserRouter>
    </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
