import React, { useEffect } from "react";
import { Input, Segment } from "semantic-ui-react";
import "../css/Main.css";
import { ReactComponent as BackgroundImage } from "../static/login.background.svg";
import { ReactComponent as LoginWindow } from "../static/login.window.svg";
import axios from "axios";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../AuthContext";

// export const useTokenMutation = () => {
//   return useMutation(async ({inputId, password}) => {
//     const url = "https://api.the-kb.com/v1/token";
//     const res = await axios.post(url , {
//       username: inputId,
//       password: password,
//     })
//     console.log(res.data.token)
//     return res.data.token;
//   })
// }



const LoginPage = ({ onLogin, sendSelectedIndex, getUser }) => {
  const [password, setPassword] = useState("");
  const [inputId, setInputId] = useState("");
  const {setToken,setMainmain} = useAuth();
  let token = "";

  const defaultStyle = {
    position: "absolute",
    top: "173px",
    left: "72px",
    color: "#FFF",
    fontSize: "30px",
    fontFamily: "NanumGothic",
    fontWeight: 700,
  };

  const { isLoading: loginLoading, mutate: getToken } = useMutation({
    mutationFn: async ({ inputId, password }) => {
      const url = "https://api.the-kb.com";
      const res = await axios.post(url+"/v1/token", {
        username: inputId,
        password: password,
      });

      token = res.data.token;

      // const [justAll, solarlight, solarheat] = 
      //   await axios.all([
      //       axios.get(url+"/v1/just/all",{
      //       headers : {Authorization : `Bearer ${token}`},
      //       }),
      //       axios.get(url+"/v1/equipment/solarlight/list",{
      //       headers : {Authorization : `Bearer ${token}`},
      //       }),
      //       axios.get(url+"/v1/equipment/solarheat/list",{
      //       headers : {Authorization : `Bearer ${token}`},
      //       }),
      //   ]);

      //   const justAllData = justAll.data;
      //   const lightData = solarlight.data;
      //   const heatData = solarheat.data;

      //   let mainData = [];

        // justAllData.forEach(allItem => {
        //     const light = lightData.find(item => item.id === allItem.equipmentId);
        //     const heat = heatData.find(item => item.id === allItem.equipmentId);


        //     if(light || heat) {

        //         //주소
        //         let addressData = '';
        //         if (light) {
        //             addressData = `${light.address.title} ${light.addressDetail}`;
        //         } else if (heat) {
        //             addressData = `${heat.address.title} ${heat.addressDetail}`;
        //         }

        //         //설비 용량
        //         let capacity = '';
        //         if (light) {
        //             capacity = light.capacity;
        //         } else if (heat) {
        //             capacity = heat.capacity;
        //         }

        //         mainData.push({
        //             id : allItem.equipmentId,
        //             energy : `${allItem.energyType1}(${allItem.energyType2})`,
        //             owner : allItem.ownerTitle,
        //             addressData : addressData,
        //             installedDate : allItem.installedDate,
        //             modelName : allItem.modelName,
        //             capacity : capacity,
        //             installer : allItem.installerTitle,
        //             maintainer : allItem.maintainerTitle,
        //             status : allItem.status,
        //         })
        //     }
        // })
        setToken(res.data.token)
        // setMainmain(mainData);
    }
  });


  const handleSubmit = () => {
    if (inputId !== "" && password !== "") {
      getToken({ inputId, password }, {
        onSuccess: (token) => {
          sendSelectedIndex(0);
          onLogin(inputId);
        }
      });
    } else {
      alert("ID와 패스워드 확인");
    }
  };
  return (
    <>
      <Segment
        as="div"
        basic
        style={{
          position: "absolute",
          top: "163px",
          left: "288px",
        }}
      >
        <BackgroundImage />
      </Segment>
      <Segment
        as="div"
        basic
        style={{
          position: "absolute",
          top: "116px",
          left: "1111px",
          width: "100px",
        }}
        size="small"
      >
        <LoginWindow
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "267px",
            left: "50px",
            width: "637px",
            height: "606px",
          }}
        >
          <div style={defaultStyle}>아이디</div>
          <Input
            transparent
            placeholder="아이디를 입력하세요"
            className="loginInput"
            value={inputId}
            onChange={(e) => setInputId(e.target.value)}
            type="text"
            style={{
              ...defaultStyle,
              top: "236px",
              fontSize: "19.5px",
              width: 503,
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <div
            style={{
              ...defaultStyle,
              top: "323.5px",
            }}
          >
            비밀번호
          </div>
          <Input
            transparent
            placeholder="***********"
            className="loginInput"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              ...defaultStyle,
              top: "387.5px",
              fontSize: "19.5px",
              width: 503,
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <div
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
              position: "absolute",
              top: "480px",
              left: "394px",
              width: "186px",
              height: "70px",
              color: "white",
              borderRadius: "55px",
              border: "1px solid white",
              fontSize: "24px",
              fontFamily: "NanumGothic",
              fontWeight: 800,
            }}
            onClick={handleSubmit}
          >
            <span style={{ margin: "20px 0 0 53px", display: "inline-block" }}>
              로그인
            </span>
          </div>
        </div>
      </Segment>
    </>
  );
};

export default LoginPage;
