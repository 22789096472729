import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [allData, setAllData] = useState([]);
  const [mainmain, setMainmain] = useState([]);
  const [issueTotal, setIssueTotal] = useState([]);
  const [facData, setFacData] = useState([]);
  const [eventMainData, setEventMainData] = useState([]);
  const [asAllData, setAsAllData] = useState([]);

  // 토큰을 설정하고 가져오는 로직 구현
  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        mainmain,
        setMainmain,
        allData,
        setAllData,
        facData,
        setFacData,
        issueTotal,
        setIssueTotal,
        setEventMainData,
        eventMainData,
        asAllData,
        setAsAllData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
