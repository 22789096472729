const Empty = ({ selectedIndex }) => {
  const getClassName = (index) => {
    switch (index) {
      case 0:
        return "mainEmpty-background";
      case 2:
        return "facEmpty-background";
      case 5:
        return "eventEmpty-background";
      default:
        return "asEmpty-background";
    }
  };

  return (
    <div className={getClassName(selectedIndex)}>
      <span>결과 데이터가 없습니다.</span>
    </div>
  );
};

export default Empty;
