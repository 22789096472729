import React from "react";
import { ReactComponent as DeleteIcon } from "../static/deleteicon.svg";
import { ReactComponent as X } from "../static/X.svg";
import { ReactComponent as DeleteBtn } from "../static/deletebtn.svg";
import axios from "axios";
import { useAuth } from "../AuthContext";

const DeletePopup = ({
  setDeleteCheck,
  selectNoteId,
  getNotesWithIssueId,
  selectIssueId,
  setSelectNoteId,
  setTempNoteList,
  setClickDiv,
  fetchIssueCallback,
}) => {
  const { token } = useAuth();
  const handleDeleteNote = async (i) => {
    const api = `https://api.the-kb.com/v1/issue/note/${i}`;
    try {
      const res = await axios.delete(api, {
        headers: { Authorization: `Bearer ${token}` },
      });
      getNotesWithIssueId(selectIssueId);
      alert("삭제되었습니다.");
      setDeleteCheck(false);
      setSelectNoteId("");
      setTempNoteList([]);
      setClickDiv(null);
      fetchIssueCallback();
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        width: "1920px",
        height: "1080px",
        backgroundColor: "rgba(0, 0, 0, .75)",
      }}
    >
      <div className="popup popup-2">
        <X
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
          }}
          onClick={(e) => {
            setDeleteCheck(false);
          }}
        />
        <DeleteIcon style={{ marginTop: "7%" }} />
        <div
          style={{
            marginTop: "15%",
            color: "#FFF",
            fontSize: "35px",
            fontFamily: "appler",
            fontWeight: 400,
          }}
        >
          해당 단계를 삭제하시겠습니까?
        </div>
        <button
          onClick={() => {
            handleDeleteNote(selectNoteId);
          }}
          className="delete-Btn"
          style={{ marginTop: "15%" }}
        >
          <DeleteBtn
            style={{
              marginRight: "10px",
            }}
            width={32}
            height={32}
          />
          <span style={{ fontSize: "20px" }}>삭제</span>
        </button>
      </div>
    </div>
  );
};

export default DeletePopup;
