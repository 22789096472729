import React, { useEffect } from "react";
import { ReactComponent as X } from "../static/X.svg";
import { ReactComponent as Complete } from "../static/complete.svg";
import { ReactComponent as CheckIcon } from "../static/checkicon.svg";
import { useState } from "react";
import { Select } from "semantic-ui-react";
import axios from "axios";
import { useAuth } from "../AuthContext";

const CompletePopup = ({
  setWriteNote,
  selectIssueId,
  getNotesWithIssueId,
  selectNoteId,
  fileInputRef,
  secondFileInputRef,
  handleFileUpload,
  noteDataCallback,
  noteData,
  fetchIssueCallback,
}) => {
  const options = [
    { key: "선택", text: "수리상태 선택" },
    { key: "OPENED", value: "접수", text: "접수" },
    { key: "WAITING_APPROVAL", value: "승인요청", text: "승인요청" },
    { key: "DISCARDED", value: "수리포기", text: "수리포기" },
    { key: "CLOSED", value: "수리완료", text: "수리완료" },
    { key: "IN_PROGRESS", value: "진행중", text: "진행중" },
    { key: "UNKNOWN", value: "알수없음", text: "알수없음" },
  ];
  const { token } = useAuth();
  const [belong, setBelong] = useState(""); //이벤트에서 소속
  const [name, setName] = useState(""); //이벤트에서 이름
  const [rank, setRank] = useState(""); //이벤트에서 직급
  const [contact, setContact] = useState(""); //이벤트에서 연락처
  const [selectStatusText, setSelectStatusText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [firstPhoneNum, setFirstPhoneNum] = useState("");
  const [secondPhoneNum, setSecondPhoneNum] = useState("");
  const [thirdPhoneNum, setThirdPhoneNum] = useState("");
  const [selectedImage, setSelectedImage] = useState(null); // 선택한 이미지의 URL을 저장할 상태

  const isNumeric = (value) => /^[0-9]*$/.test(value);

  const handleFirstPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 3);
    if(isNumeric(inputText)){
      setFirstPhoneNum(inputText);
      updateContact(`${inputText}-${secondPhoneNum}-${thirdPhoneNum}`);
      setModified(true);
    }
  };

  const handleSecondPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 4);
    if(isNumeric(inputText)){
      setSecondPhoneNum(inputText);
      updateContact(`${firstPhoneNum}-${inputText}-${thirdPhoneNum}`);
      setModified(true);
    }
  };

  const handleThirdPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 4);
    if(isNumeric(inputText)){
      setThirdPhoneNum(inputText);
      updateContact(`${firstPhoneNum}-${secondPhoneNum}-${inputText}`);
      setModified(true);
    }
  };

  const updateContact = (newContact) => {
    setContact(newContact);
  };

  const initialBelong = noteData?.writer?.group || "";
  const initialName = noteData?.writer?.name || "";
  const initialTitle = noteData?.writer?.title || "";
  const initialFirst = noteData?.writer?.contact?.split("-")[0] || "";
  const initialSecond = noteData?.writer?.contact?.split("-")[1] || "";
  const initialThird = noteData?.writer?.contact?.split("-")[2] || "";
  const initialText = noteData?.text || "";

  async function noteUpdate(id) {
    const api = `https://api.the-kb.com/v1/issue/note/${id}`;

    if (
      selectStatusText === "" ||
      noteContent === "" ||
      belong === "" ||
      name === "" ||
      rank === "" ||
      contact === ""
    ) {
      alert("비어있는 항목값이 있습니다.");
      fileInputRef.current.value = null;
      setSelectedImage(null);
      return null;
    }

    try {
      const res = await axios.put(
        api,
        {
          status: selectStatusText,
          text: noteContent,
          writer: {
            group: belong,
            name: name,
            title: rank,
            contact: contact,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      handleFileUpload(id);
      getNotesWithIssueId(selectIssueId);
      noteDataCallback(selectNoteId);
      alert("수정이 완료되었습니다.");
      setWriteNote(false);
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const handleImagePreview = (e) => {
    const files = Array.from(e.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setSelectedImage(imageUrls);
  };

  const handleImageDelete = (imgIndex) => {
    setSelectedImage((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(imgIndex, 1);
      fileInputRef.current.value = null;
      return newImages;
    });
  };

  const [modified, setModified] = useState(false);

  useEffect(() => {
    setBelong(initialBelong);
    setName(initialName);
    setRank(initialTitle);
    setSelectStatusText("");
    setSelectedStatus("");
    setNoteContent(initialText);
    setFirstPhoneNum(initialFirst);
    setSecondPhoneNum(initialSecond);
    setThirdPhoneNum(initialThird);
    setSelectedImage(null);
    setContact(noteData?.writer?.contact || "");
  }, [
    initialBelong,
    initialName,
    initialTitle,
    initialText,
    initialFirst,
    initialSecond,
    initialThird,
    noteData,
  ]);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        width: "1920px",
        height: "1080px",
        backgroundColor: "rgba(0, 0, 0, .75)",
      }}
    >
      <div className="popup popup-3">
        <X
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
          }}
          onClick={(e) => {
            setWriteNote(false);
          }}
        />
        <Complete />
        <div
          style={{
            marginTop: "30px",
            color: "#FFF",
            fontSize: "35px",
            fontFamily: "appler",
            fontWeight: 400,
          }}
        >
          수정 하시겠습니까?
        </div>
        <div
          style={{
            marginTop: "60px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Select
            placeholder="수리상태 선택"
            options={options}
            style={{
              color: "white",
              fontSize: "20px",
              marginTop: "-13px",
              height: "53px",
              width: "480px",
              backgroundColor: "#626468",
            }}
            value={selectedStatus}
            onChange={(e) => {
              const selectedOption = options.find(
                (option) => option.text === e.currentTarget.textContent
              );
              setSelectedStatus(selectedOption.text)
              setSelectStatusText(selectedOption.key);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "60px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            소속*
          </span>
          <input
            id="belong"
            value={modified ? belong : initialBelong}
            style={{
              width: "414px",
              backgroundColor: "transparent",
              color: belong ? "#FFF" : "gray",
              fontSize: "20px",
              fontFamily: "appler",
              fontWeight: 400,
              paddingBottom: "16px",
              borderBottom: "1px solid #4e5054",
              outline: 0,
            }}
            placeholder={initialBelong}
            onChange={(e) => {
              setBelong(e.target.value);
              setModified(true);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            이름*
          </span>
          <input
            id="name"
            value={modified ? name : initialName}
            style={{
              width: "414px",
              backgroundColor: "transparent",
              color: name ? "#FFF" : "gray",
              fontSize: "20px",
              fontFamily: "appler",
              fontWeight: 400,
              paddingBottom: "16px",
              borderBottom: "1px solid #4e5054",
              outline: 0,
            }}
            placeholder={initialName}
            onChange={(e) => {
              setName(e.target.value);
              setModified(true);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "#FFF",
              fontSize: "24px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            직급*
          </span>
          <input
            id="rank"
            value={modified ? rank : initialTitle}
            style={{
              width: "414px",
              backgroundColor: "transparent",
              color: rank ? "#FFF" : "gray",
              fontSize: "20px",
              fontFamily: "appler",
              fontWeight: 400,
              paddingBottom: "16px",
              borderBottom: "1px solid #4e5054",
              outline: 0,
            }}
            placeholder={initialTitle}
            onChange={(e) => {
              setRank(e.target.value);
              setModified(true);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "row",
            marginLeft: "-19%",
          }}
        >
          <span
            style={{
              marginLeft: "80px",
              color: "#FFF",
              fontSize: "21px",
              fontFamily: "appler",
              fontWeight: 400,
              width: "72px",
            }}
          >
            연락처*
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              placeholder={initialFirst}
              value={modified ? firstPhoneNum : initialFirst}
              maxLength={3}
              onChange={handleFirstPartChange}
              style={{
                textAlign: "center",
                color: initialFirst ? "#FFF" : "gray",
                fontSize: "20px",
                height: "55px",
                maxWidth: "80px",
                backgroundColor: "transparent",
                border: "1px solid #75777b",
                marginLeft: "20px",
                marginRight: "4px",
              }}
            />
            <div
              style={{
                marginLeft: "12px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "appler",
                fontWeight: "400",
              }}
            >
              -
            </div>
            {/* 두 번째 부분 */}
            <input
              type="text"
              placeholder={initialSecond}
              value={modified ? secondPhoneNum : initialSecond}
              maxLength={4}
              onChange={handleSecondPartChange}
              style={{
                textAlign: "center",
                height: "55px",
                fontSize: "20px",
                maxWidth: "100px",
                color: initialSecond ? "#FFF" : "gray",
                backgroundColor: "transparent",
                border: "1px solid #75777b",
                marginLeft: "20px",
                marginRight: "4px",
              }}
            />
            <div
              style={{
                marginLeft: "12px",
                color: "#FFF",
                fontSize: "16px",
                fontFamily: "appler",
                fontWeight: "400",
              }}
            >
              -
            </div>
            {/* 세 번째 부분 */}
            <input
              type="text"
              placeholder={initialThird}
              value={modified ? thirdPhoneNum : initialThird}
              maxLength={4}
              onChange={handleThirdPartChange}
              style={{
                textAlign: "center",
                height: "55px",
                fontSize: "20px",
                maxWidth: "100px",
                color: initialThird ? "#FFF" : "gray",
                backgroundColor: "transparent",
                border: "1px solid #75777b",
                marginLeft: "20px",
                marginRight: "4px",
              }}
            />
          </span>
        </div>
        <textarea
          className="update-write-content"
          value={modified ? noteContent : initialText}
          placeholder={initialText}
          style={{
            color: noteContent ? "#FFF" : "gray",
          }}
          onChange={(e) => {
            setNoteContent(e.target.value, setModified(true));
          }}
        />
        {/* 작업내용 */}
        <div style={{ margin: "30px 0 40px 0", height: "20px" }}>
          <input
            ref={secondFileInputRef}
            type="file"
            id="second-file-upload"
            style={{ display: "none" }}
            onChange={() => {
              handleFileUpload(selectNoteId);
            }}
          />
          <input
            ref={fileInputRef}
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            onChange={handleImagePreview} // 파일 선택 시 이미지 미리보기 처리
            multiple
          />
          <button
            className="upload-btn"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <span style={{ fontWeight: 700, cursor: "pointer" }}>
              수정페이지 파일 첨부
            </span>
          </button>
          <div style={{ marginTop: "20px" }}>
            {selectedImage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {selectedImage.map((imageUrl, imgIndex) => (
                    <div
                      key={imgIndex}
                      style={{
                        position: "relative",
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        className="pri-img"
                        src={imageUrl}
                        alt=""
                        style={{ maxWidth: "300px", maxHeight: "300px" }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          bottom: "-10px",
                          right: "4px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "red",
                          color: "#fff",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageDelete(imgIndex)}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className="update-btn"
          onClick={() => {
            noteUpdate(selectNoteId);
            fetchIssueCallback();
          }}
        >
          <CheckIcon
            style={{
              marginRight: "5px",
            }}
            width={32}
            height={25}
          />
          수정완료
        </button>
      </div>
    </div>
  );
};

export default CompletePopup;
