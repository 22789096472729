import Spinner from "../static/loading.gif";

const Loading = ({className = ""}) => {
  return (
    <div className={`loading-background ${className}`}>
      <span className="loading">Loading...</span>
      <img src={Spinner} alt="로딩중" width="11%" />
    </div>
  );
};

export default Loading;
